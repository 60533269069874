import { AdventCalendar, Bibliobattles, CustomCampaign, Documents, Sommerles, ValueOf } from "@libry-content/types";

const hasType = (item: unknown): item is { _type: string } =>
  !!item && typeof item === "object" && typeof (item as any)?.["_type"] === "string";

type SanityDocumentType = Documents["_type"];

export type CustomCampaignType = CustomCampaign["_type"];

export const customCampaignType: CustomCampaignType = "customCampaign";

const isCustomCampaignType = (item: unknown): item is CustomCampaignType => item === customCampaignType;

export const isCustomCampaign = (item: unknown): item is CustomCampaign =>
  hasType(item) && isCustomCampaignType(item._type);

export const templateCampaignTypes = ["sommerles", "adventCalendar", "bibliobattles"] satisfies SanityDocumentType[];

export const campaignTypes = [customCampaignType, ...templateCampaignTypes] satisfies SanityDocumentType[];

export type CampaignType = (typeof campaignTypes)[number];

export const isCampaignType = (item: unknown): item is CampaignType =>
  typeof item === "string" && (campaignTypes as string[]).includes(item);

export const isCampaign = (item: Documents): item is CustomCampaign | TemplateCampaign =>
  (campaignTypes as string[]).includes(item._type);

export type Campaign = Extract<Documents, { _type: CampaignType }>;

export type TemplateCampaignType = (typeof templateCampaignTypes)[number];

export const isTemplateCampaignType = (item: unknown): item is TemplateCampaignType =>
  typeof item === "string" && (templateCampaignTypes as string[]).includes(item);

export const isAdventCalendarCampaign = <T extends AdventCalendar>(item: unknown): item is T =>
  hasType(item) && item._type === "adventCalendar";

export const isBibliobattlesCampaign = <T extends Bibliobattles>(item: unknown): item is T =>
  hasType(item) && item._type === "bibliobattles";

export const isSommerlesCampaign = <T extends Sommerles>(item: unknown): item is T =>
  hasType(item) && item._type === "sommerles";

export const isTemplateCampaign = <T extends Omit<TemplateCampaign, "templateContent">>(item: unknown): item is T =>
  hasType(item) && isTemplateCampaignType(item._type);

export type TemplateCampaign = Extract<Documents, { _type: TemplateCampaignType }>;

export const templateCampaignSlugs = {
  adventCalendar: "julekalender",
  bibliobattles: "bibliobattles",
  sommerles: "sommerles",
} as const satisfies Record<TemplateCampaignType, string>;

type TemplateCampaignSlug = ValueOf<typeof templateCampaignSlugs>;

export const isTemplateCampaignSlug = (item: unknown): item is TemplateCampaignSlug =>
  typeof item === "string" && (Object.values(templateCampaignSlugs) as string[]).includes(item);

export const templateCampaignTypeFromSlug = (slug: TemplateCampaignSlug) =>
  Object.entries(templateCampaignSlugs).find(([_, campaignSlug]) => campaignSlug === slug)?.[0] as TemplateCampaignType;

export const templateCampaignContentTypes = [
  "adventCalendarTemplate",
  "sommerlesSettings",
  "bibliobattlesTemplateContent",
] satisfies SanityDocumentType[];

export type TemplateCampaignContentType = (typeof templateCampaignContentTypes)[number];
