import { isJestTest } from "./platform";

/**
 * Normalizes ISBN by removing dashes and converting to ISBN-13 if ISBN is originally ISBN-10.
 *
 * Use for logical operations (such as comparing ISBNs).
 *
 * Do not use when displaying ISBN related to a book.
 */
export const normalizeIsbn = (isbn?: string) => {
  const normalizedIsbn = isbn?.replace(/[^0-9x]/gi, "");

  if (!normalizedIsbn) return isbn;

  if (normalizedIsbn.length == 13) return normalizedIsbn;

  if (normalizedIsbn.length !== 10) {
    !isJestTest &&
      console.error(`isbn ${normalizedIsbn} is wrong length, should be 10 or 13 characters long to be valid`);
    return normalizedIsbn;
  }
  const isbnNumberArray = normalizedIsbn
    .split("")
    .map((char) => (char.toUpperCase() === "X" ? 10 : Number.parseInt(char)));
  const isbnNumberArrayForConversion = [9, 7, 8, ...isbnNumberArray].slice(0, 12);
  const sum = isbnNumberArrayForConversion.reduce((acc, current, index) => acc + current * (index % 2 ? 3 : 1));
  const lastDigit = 10 - (sum % 10);
  return [...isbnNumberArrayForConversion, lastDigit == 10 ? 0 : lastDigit].reduce(
    (acc, currentValue) => `${acc}${currentValue}`,
    "" as string
  );
};
