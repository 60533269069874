import { editorLocales } from "@libry-content/localization";
import groq from "groq";

// This groq query can be used to create a string-excerpt from a rich-text field
// https://stackoverflow.com/questions/73962212/it-it-possible-to-truncate-text-in-a-groq-query
export const createLocalizedExcerptFromRichText = (fieldName: string, size: number) => {
  const localizedExcerpts = editorLocales.map(
    (code) =>
      groq`
      "${code}": array::join(string::split((pt::text(
          ${fieldName}.${code}
            // Filter out all non-text nodes, like tables, images, etc.
            [_type == "block"]
            // To reduce the heaviness of the query, we only parse the first 2 blocks, it should be enough to get a good excerpt
            [0...2]
        )), "")[0...${size}], "")
        + "..."
      `
  );

  return groq`{ ${localizedExcerpts.join(", ")} }`;
};
