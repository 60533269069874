import { EditorLocale, dateFnsLocaleMap } from "@libry-content/localization";
import addDays from "date-fns/addDays";
import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes";
import dateFnsFormat from "date-fns/format";
import formatDuration from "date-fns/formatDuration";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import { UTCDateToNorwegian, norwegianDateToUTC } from "./date";

/**
 * Opening hours etc may be submitted as e.g. "8:00"
 */
export const getPaddedTime = (timeString: string): string => {
  const [hours, minutes] = timeString.split(":");

  if (!hours || !minutes || minutes.length !== 2) {
    console.error("Could not parse time string", timeString);
    return timeString;
  }

  return `${hours.padStart(2, "0")}:${minutes}`;
};
export class DateHelper {
  public date: Date;

  constructor(date: Date | string) {
    this.date = new Date(date);
  }

  /**
   * Takes a timestring on the format HH:mm, interpreted in Norwegian timezone,
   * and converts to a DateHelper where the date is set to Norway's current day
   */
  public static fromTime(time?: string) {
    const now = new DateHelper(DateHelper.now());
    if (!time) return now;
    return this.fromTimeAndDate(time, now.toNorwegianISODateString());
  }

  /**
   * Takes a timestring on the format HH:mm and a datestring on the form yyyy-MM-dd, interpreted in Norwegian timezone,
   * and converts to a DateHelper
   */
  public static fromTimeAndDate(time: string, date: string) {
    return new DateHelper(norwegianDateToUTC(`${date}T${getPaddedTime(time)}`));
  }

  // Returns the date as a norwegian timezoned string on format yyyy-MM-dd
  public toNorwegianISODateString(): string {
    return dateFnsFormat(UTCDateToNorwegian(this.date), "yyyy-MM-dd", { locale: dateFnsLocaleMap["nb"] }); // ikke noe poeng å støtte nynorsk her da det er formatet yyyy-MM-dd vi er ute etter, og nn og nb er i samme tidssone
  }

  public static now() {
    return new Date();
  }

  public static daysFromNow(days: number) {
    return addDays(DateHelper.now(), days);
  }

  public get minutesFromNow() {
    return differenceInMinutes(this.date, DateHelper.now());
  }

  public get hoursFromNow() {
    return differenceInHours(this.date, DateHelper.now());
  }

  public formatMinutesToNow(languageCode: EditorLocale, roundDownToNearestMinutes = 1): string {
    const minutesToNow = differenceInMinutes(this.date, DateHelper.now());
    const roundedMinutesToNow = roundDownToNearestMinutes * Math.floor(minutesToNow / roundDownToNearestMinutes);
    const locale = dateFnsLocaleMap[languageCode];
    const formatter = (minutes: number) => formatDuration({ minutes }, { locale });

    if (roundedMinutesToNow === 0) {
      console.error(`Attempted to round ${minutesToNow} to nearest ${roundDownToNearestMinutes} minutes`);
      return formatter(roundDownToNearestMinutes);
    }

    return formatter(roundedMinutesToNow);
  }

  public get isToday() {
    return isToday(this.date);
  }

  public get isTomorrow() {
    return isTomorrow(this.date);
  }

  public isBefore(date: Date | DateHelper) {
    return isBefore(this.date, date instanceof DateHelper ? date.date : date);
  }

  public isBeforeNow() {
    return this.isBefore(DateHelper.now());
  }

  public isSameOrBeforeNow() {
    return !this.isAfterNow();
  }

  public isAfter(date: Date | DateHelper) {
    return isAfter(this.date, date instanceof DateHelper ? date.date : date);
  }

  public isAfterNow() {
    return this.isAfter(DateHelper.now());
  }

  public isSameDay(date: Date) {
    return isSameDay(this.date, date);
  }

  public get isTodayOrLater() {
    return this.isToday || this.isAfter(DateHelper.now());
  }

  public isSameDateOrBefore(date: Date) {
    return this.isBefore(date) || this.isSameDay(date);
  }

  public isSameDateOrAfter(date: Date) {
    return this.isAfter(date) || this.isSameDay(date);
  }

  public static getLabel(code: EditorLocale, date?: string | Date, format?: string) {
    if (!date) return "ukjent dato";
    const helper = new DateHelper(date);
    if (helper.isToday) return "i dag";
    if (helper.isTomorrow) return "i morgen";
    return dateFnsFormat(helper.date, format || "eee d. LLL", { locale: dateFnsLocaleMap[code] });
  }

  public static timeStringDifferenceInMinutes(timeA?: string, timeB?: string): number {
    if (!timeA || !timeB) return NaN;
    return differenceInMinutes(DateHelper.fromTime(timeA).date, DateHelper.fromTime(timeB).date);
  }
}
