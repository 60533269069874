import groq from "groq";
import { resolveRepeatedEvents } from "./repeatedEvents";
import { groqEventStart } from "./utils";

const notRepeated = groq`(!repeated || !defined(repeated))`;
const notAllDay = groq`(!allDay || !defined(allDay))`;

export const eventOnDateOrLater = (dateVariable: string) => groq`(
  (allDay && ${notRepeated} && endDate >= ${dateVariable}) ||
  (${notAllDay} && ${notRepeated} && startDate >= ${dateVariable}) ||
  (repeated && count(repeatedDates[@.date >= ${dateVariable}]) > 0)
)`;

export const eventBeforeDate = (dateVariable: string) => groq`(
  (allDay && ${notRepeated} && endDate < ${dateVariable}) ||
  (${notAllDay} && ${notRepeated} && startDate < ${dateVariable}) ||
  (repeated && count(repeatedDates[@.date < ${dateVariable}]) > 0)
)`;

const repeatedEventNotFinished = groq`(@.date > $today || @.date == $today && (coalesce(@.customization.eventEnd, ^.eventEnd) > $timeNow))`;
const repeatedEventFinished = groq`(@.date < $today || @.date == $today && (coalesce(@.customization.eventEnd, ^.eventEnd) < $timeNow))`;

export const eventFilters = Object.freeze({
  eventTodayOrLater: eventOnDateOrLater("$today"),
  eventNotFinished: groq`(
    ${notRepeated} && (
      (allDay && endDate >= $today) ||
      (${notAllDay} && (startDate > $today || startDate == $today && eventEnd > $timeNow))
    ) ||
    (repeated && count(repeatedDates[${repeatedEventNotFinished}]) > 0)
  )`,
  eventBeforeToday: eventBeforeDate("$today"),
  eventFinished: groq`(
    ${notRepeated} && (
      (allDay && endDate < $today) ||
      (${notAllDay} && (startDate < $today || startDate == $today && eventEnd < $timeNow))
    ) ||
    (repeated && (count(repeatedDates[${repeatedEventFinished}]) > 0))
  )`,
  eventLacksDates: groq`(
    (allDay && ${notRepeated} && !defined(endDate)) ||
    (${notAllDay} && ${notRepeated} && !defined(startDate)) ||
    (repeated && count(repeatedDates) == 0)
  )`,
  repeatedEventNotFinished,
  repeatedEventFinished,
});

export const unFinishedEventsGroqFilter = groq`
  *[_type == 'event' && ${eventFilters.eventNotFinished}]
    ${resolveRepeatedEvents(eventFilters.repeatedEventNotFinished)}
    | order(startDate asc, ${groqEventStart} asc)
`;

export const finishedEventsGroqFilter = groq`
  *[_type == 'event' && ${eventFilters.eventFinished}]
    ${resolveRepeatedEvents(eventFilters.repeatedEventFinished)}
    | order(startDate desc, ${groqEventStart} desc)
`;
