import { EbookRedia, MusicRedia, NewspaperIcon, VideoRedia } from "@biblioteksentralen/icons";
import {
  BibliofilAppService,
  BiblioteketAppService,
  DigitalLibraryCustomService,
  DigitalLibraryServiceCategory,
  Documents,
  objectKeys,
} from "@libry-content/types";
import { Archive, Icon, Monitor, Smartphone } from "react-feather";

// ----------- Hardcoded definitions; the orders are significant -----------

export const digitalLibraryServiceCategories = {
  "library-app": {
    nb: "Bibliotekets app",
    nn: "Appen til biblioteket",
  },
  "ebooks-and-audiobooks": {
    nb: "E-bøker og lydbøker",
    nn: "E-bøker og lydbøker",
  },
  "film-services": {
    nb: "Filmtjenester",
    nn: "Filmtenester",
  },
  "music-and-podcasts": {
    nb: "Musikk og podkaster",
    nn: "Musikk og podkastar",
  },
  "newspapers-and-magazines": {
    nb: "Aviser og magasiner",
    nn: "Aviser og magasin",
  },
  "local-history-genealogy-and-archive": {
    nb: "Lokalhistorie, slektsgransking og arkiv",
    nn: "Lokalhistorie, slektsgransking og arkiv",
  },
  "other-digital-resources": {
    nb: "Andre digitale ressurser",
    nn: "Andre digitale ressursar",
  },
} as const;

export const digitalLibraryServiceTemplates = {
  bibliofilApp: "library-app",
  biblioteketApp: "library-app",
  bookbites: "ebooks-and-audiobooks",
  verdensbiblioteket: "ebooks-and-audiobooks",
  digitaltNasjonaltLanekort: "other-digital-resources",
  bibliotekSok: "other-digital-resources",
  filmbib: "film-services",
  filmoteket: "film-services",
  nasjonalbiblioteketsNettbibliotek: "other-digital-resources",
  libby: "ebooks-and-audiobooks",
  allbok: "ebooks-and-audiobooks",
  flipp: "newspapers-and-magazines",
  idunn: "other-digital-resources",
  cineast: "film-services",
  naxos: "music-and-podcasts",
  lovdataPro: "other-digital-resources",
  pressreader: "newspapers-and-magazines",
  comicsplus: "ebooks-and-audiobooks",
  tibi: "other-digital-resources",
} as const;

const servicesRequiringAuthentication: DigitalLibraryTemplateCode[] = ["pressreader", "comicsplus", "flipp"];

// ----------- Derived types -----------

export const digitalLibraryTemplateCodes = objectKeys(digitalLibraryServiceTemplates);

export type DigitalLibraryTemplateCode = (typeof digitalLibraryTemplateCodes)[number];

export const isDigitalLibraryTemplateCode = (item: unknown): item is DigitalLibraryTemplateCode =>
  typeof item === "string" && (digitalLibraryTemplateCodes as readonly string[]).includes(item);

// ----------- Templates (edited in the admin studio) -----------

export type DigitalLibraryTemplateType = `${DigitalLibraryTemplateCode}Template`;

export type DigitalLibraryTemplate = Extract<Documents, { _type: DigitalLibraryTemplateType }>;

export const digitalLibraryCodeToTemplateType = (code: DigitalLibraryTemplateCode): DigitalLibraryTemplateType =>
  `${code}Template`;

export const digitalLibraryTemplateTypes = digitalLibraryTemplateCodes.map(digitalLibraryCodeToTemplateType);

export const isDigitalLibraryTemplateType = (item: unknown): item is DigitalLibraryTemplateType =>
  typeof item === "string" && (digitalLibraryTemplateTypes as readonly string[]).includes(item);

export const isDigitalLibraryTemplate = (item: unknown): item is DigitalLibraryTemplate =>
  typeof item === "object" && isDigitalLibraryTemplateType((item as any)?.["_type"]);

// ----------- Template services (edited in the site studio) -----------

export type DigitalLibraryTemplateServiceType = `${DigitalLibraryTemplateCode}Service`;

export type DigitalLibraryTemplateService = Extract<Documents, { _type: DigitalLibraryTemplateServiceType }>;

export const digitallLibraryCodeToServiceType = (code: DigitalLibraryTemplateCode): DigitalLibraryTemplateServiceType =>
  `${code}Service`;

export const digitalLibraryTemplateServiceTypes = digitalLibraryTemplateCodes.map(digitallLibraryCodeToServiceType);

// ----------- Custom services (edited in the site studio) -----------

export const isDigitalLibraryCustomServiceType = (item: unknown): item is DigitalLibraryCustomService["_type"] =>
  item === "digitalLibraryCustomService";

export const isDigitalLibraryCustomService = (item: unknown): item is DigitalLibraryCustomService =>
  typeof item === "object" && isDigitalLibraryCustomServiceType((item as any)?.["_type"]);

// ----------- General services (template services and custom services together) -----------

export const digitalLibraryServiceTypes = [
  "digitalLibraryCustomService",
  ...digitalLibraryTemplateServiceTypes,
] as const;

export const digitalLibraryFilter = `_type in ${JSON.stringify(digitalLibraryServiceTypes)}`;

export type DigitalLibraryServiceType = (typeof digitalLibraryServiceTypes)[number];

export type DigitalLibraryService = Extract<Documents, { _type: DigitalLibraryServiceType }>;

export const isDigitalLibraryServiceType = (item: unknown): item is DigitalLibraryServiceType =>
  typeof item === "string" && (digitalLibraryServiceTypes as readonly string[]).includes(item);

export const isDigitalLibraryService = (item: unknown): item is DigitalLibraryService =>
  typeof item === "object" && isDigitalLibraryServiceType((item as any)?.["_type"]);

// ----------- Utils -----------

export const digitalLibraryTemplateConstantObject = <T>(value: T) =>
  Object.fromEntries(
    digitalLibraryTemplateCodes.map((code) => [digitalLibraryCodeToTemplateType(code), value])
  ) as Record<DigitalLibraryTemplateType, T>;

export const digitalLibraryTemplateServiceConstantObject = <T>(value: T) =>
  Object.fromEntries(
    digitalLibraryTemplateCodes.map((code) => [digitallLibraryCodeToServiceType(code), value])
  ) as Record<DigitalLibraryTemplateServiceType, T>;

export const isBibliofilAppService = <T extends Pick<BibliofilAppService, "_type">>(item: unknown): item is T =>
  typeof item === "object" && (item as any)?.["_type"] === "bibliofilAppService";

export const isBiblioteketAppService = <T extends Pick<BiblioteketAppService, "_type">>(item: unknown): item is T =>
  typeof item === "object" && (item as any)?.["_type"] === "biblioteketAppService";

export const digitalLibraryServiceTypesRequiringAuthentication = servicesRequiringAuthentication.map(
  digitallLibraryCodeToServiceType
);

export const isAuthenticatedService = (item: object | undefined) =>
  typeof item === "object" &&
  (item as any)?.["_type"] &&
  digitalLibraryServiceTypesRequiringAuthentication.includes((item as any)["_type"]);

export const digitalLibraryServiceCategoryIcons: Record<DigitalLibraryServiceCategory, Icon> = {
  "library-app": Smartphone,
  "ebooks-and-audiobooks": EbookRedia,
  "film-services": VideoRedia,
  "music-and-podcasts": MusicRedia,
  "newspapers-and-magazines": NewspaperIcon,
  "local-history-genealogy-and-archive": Archive,
  "other-digital-resources": Monitor,
};
