// Slenger på .sort() fordi de lengste strengene bør gi treff først. Hvis ikke "folkebibliotek" ligger før "bibliotek" får vi feks rare treff som "Tysnes folke"
const predefinedUnWeightedSegments = ["folkebibliotek", "bibliotek", "bibliotekene", "ryfylkebiblioteket"].sort(
  (a, b) => b.length - a.length
);

export const getWeightedSiteNameParts = (name?: string): { value: string; bold: boolean; padRight?: boolean }[] => {
  if (!name?.length) {
    return [];
  }

  const nextUnboldPart = predefinedUnWeightedSegments
    .map((segment) => {
      const index = name.toLowerCase().indexOf(segment);
      // Dersom man har lagt inn biblioteksnavnet med mellomrom i editor må mellomrommet tas vare på i segmentet som kommer før mellomrommet. Hvis mellomrommet havner i segmentet etter istedenfor vil det gi feil indenting ved linjeskift.
      // Eks skal beholde mellomrom "Holmestrand bibliotek"
      // Eks skal ikke ha mellomrom: "Sandefjordbibliotekene"
      const trailingWhitespace = name.slice(index + segment.length).match(/^\s+/)?.[0] || "";
      return {
        index,
        value:
          // Bruker name.slice(...) istedenfor segment for å ta vare på casing, og "segment.length + trailingWhitespace.length" for å ta vare på mellomrom
          name.slice(index, index + segment.length + trailingWhitespace.length),
      };
    })
    .filter((part) => part.index > -1) // Remove segments that are not found
    .sort((a, b) => a.index - b.index)[0]; // Sort by index so we get the first match

  if (!nextUnboldPart) {
    return [{ value: name, bold: true }];
  }

  if (nextUnboldPart.index === 0) {
    return [
      { value: nextUnboldPart.value, bold: false },
      ...getWeightedSiteNameParts(name.slice(nextUnboldPart.value.length)),
    ];
  }

  return [
    { value: name.slice(0, nextUnboldPart.index), bold: true },
    ...getWeightedSiteNameParts(name.slice(nextUnboldPart.index)),
  ];
};
