import { Site } from "@libry-content/types";
import { getSubdomainUrl, platformUrl } from "./platform";

const addDefaulLanguage = (site: Pick<Site, "defaultLanguage">, skipLanguage: boolean, url: string): string =>
  skipLanguage || !site.defaultLanguage ? url : `${url}/${site.defaultLanguage}`;

/**
 * Get the base URL for a given site.
 *
 * @param site - Site instance.
 * @returns URL
 */
export const getSiteUrl = (
  site: Pick<Site, "customDomain" | "subdomain" | "defaultLanguage" | "_type">,
  { skipLanguage } = { skipLanguage: false }
): string =>
  addDefaulLanguage(
    site,
    skipLanguage,
    site.customDomain ? `https://${site.customDomain}` : getSubdomainUrl(site.subdomain)
  );

export const adminSiteUrl = new URL(`${platformUrl.protocol}//admin.${platformUrl.host}`);

export const splashSiteUrl = new URL(`${platformUrl.protocol}//${platformUrl.host}`);
