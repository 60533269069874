import groq from "groq";

/**
 * Ensure that all queries are scoped to the current site.
 * Wrapping entire query with a site query so "^._id" refers to the sites _id in nested filters
 * Using "site._ref == ^._id" instead of "site->domain == $siteDomain" doubles speed of queries: https://www.sanity.io/docs/high-performance-groq#68c7065755fd
 * This means the enitre query must be wrapped with a site-query so "^" refers to the current site
 */
export const scopeQueryToSite = (query: string) => {
  if (!query.trim().match(/^{/))
    throw new Error(`Groq-queries must be wrapped in "{}" for site-scoping to work. Query: ${query.slice(0, 100)}`);
  return (
    groq`*[_type == "site" && domain == $siteDomain][0]` +
    query
      // Scope query to only match docuemnts from the current site
      .replace(/\* *\[/g, "*[ site._ref == ^._id && ")
      // Gives us an escape hatch to query for documents globally, also outside of the current site
      .replace(/GLOBAL\[/g, "*[")
  );
};
