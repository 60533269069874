import { EditorLocale } from "@libry-content/localization";

const localizedNumbers: Record<EditorLocale, Record<number, string>> = {
  nb: {
    1: "en",
    2: "to",
    3: "tre",
    4: "fire",
    5: "fem",
    6: "seks",
    7: "sju",
    8: "åtte",
    9: "ni",
    10: "ti",
  },
  nn: {
    1: "ein",
    2: "to",
    3: "tre",
    4: "fire",
    5: "fem",
    6: "seks",
    7: "sju",
    8: "åtte",
    9: "ni",
    10: "ti",
  },
  de: {
    1: "eins",
    2: "zwei",
    3: "drei",
    4: "vier",
    5: "fünf",
    6: "sechs",
    7: "sieben",
    8: "acht",
    9: "neun",
    10: "zehn",
  },
};

export const numberToWord = (languageCode: EditorLocale, num: number): string =>
  localizedNumbers[languageCode][num] ?? num.toString();
