import { Recommendation } from "@libry-content/types";
import groq from "groq";

export const resolveRecommendationSummaryGroqProjection = groq`
  {
    _id,
    _type,
    teaser,
    publication,
    promotion,
    targetAudiences,
    description,
  }
`;

export type ResolvedRecommendationSummary = Pick<
  Recommendation,
  "_id" | "_type" | "teaser" | "publication" | "promotion" | "targetAudiences" | "description"
>;
