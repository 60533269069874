import { Site } from "@libry-content/types";
import { getSiteUrl } from "./currentSite";

export const getBookCoverOGImageUrl = (site: Site, imageSrc?: string) =>
  `${getDomain(site)}/api/og-images/book-cover/${encodeURIComponent(
    imageSrc ??
      "" /* Fallback to empty string (""). The endpoint will then return a fallback-image to use for books where we don't have a cover-image*/
  )}`;

export const getBookFanOGImageUrl = (imageUrls: string[], site: Site) =>
  `${getDomain(site)}/api/og-images/bookfan/${encodeURIComponent(imageUrls.slice(0, 3).join(","))}`;

export const getAgentOgImageUrl = (site: Site, imageSrc?: string) =>
  `${getDomain(site)}/api/og-images/agent/${encodeURIComponent(
    imageSrc ??
      "" /* Fallback to empty string (""). The endpoint will then return a fallback-image to use for agents where we don't have an image*/
  )}`;

export const getFallbackOgImageUrl = (site?: Site) => (site ? `${getDomain(site)}/api/og-images/fallback` : undefined);

const getDomain = (site: Site) =>
  getSiteUrl(site, {
    skipLanguage: true,
  });
