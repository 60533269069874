import groq from "groq";
import { eventFilters } from "./eventFilters";
import { resolveRepeatedEvents } from "./repeatedEvents";
import { groqEventStart } from "./utils";
import { resolveEventGroqProjection } from "./resolveEvent";

// #eventIdOrKey since we need to handle repeated events. A repeated event occurence is identified by its _key in the repeatedDates array
export const singleEventQuery = groq`*[ 
  _type == "event" && 
  (
    _id == $eventIdOrKey || 
    $eventIdOrKey in repeatedDates[]._key
  )
]
${resolveRepeatedEvents(groq`
    $eventIdOrKey == ^._id && (${eventFilters.repeatedEventNotFinished}) || // If we query by the id of an event with repeated dates (and not a specific repeated date by querying the _key), we want to return the first upcomming repeated date. This is forexample used in preview
    _key == $eventIdOrKey
`)}
| order(startDate asc, ${groqEventStart} asc)
[0]
{ ${resolveEventGroqProjection} }
`;
