"use client";

import { RefObject, useEffect, useRef, useState } from "react";

export const useIsIframeReady = (iframeUrl: string | undefined): [boolean, RefObject<HTMLIFrameElement>] => {
  const [isReady, setIsReady] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Make sure iframe.src is not assigned before React has time to add the "load" listener:
  // https://github.com/facebook/react/issues/6541#issuecomment-1174249634
  useEffect(() => {
    const iframeNode = iframeRef.current;
    if (!iframeNode || !iframeUrl) return;

    iframeNode.src = iframeUrl;
    const onLoad = () => setIsReady(true);
    iframeNode.addEventListener("load", onLoad);

    return () => iframeNode?.removeEventListener("load", onLoad);
  }, [iframeUrl]);

  return [isReady, iframeRef];
};
