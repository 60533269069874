import groq from "groq";
import { getPromotionFilter } from "../promotion/utils";
import { AdventCalendar, Promotion } from "@libry-content/types";

export const adventCalendarGroqFilter = groq`_type == "adventCalendar" && isEnabled && ${getPromotionFilter(
  "templateContent ->"
)}`;

export const resolveAdventCalendar = groq`
  ...,
  "promotion": templateContent->promotion
`;

export type ResolvedAdventCalendar = AdventCalendar & { promotion: Promotion };

export const resolveAdventCalendarSummary = groq`_id, _type, "promotion": templateContent->promotion`;

export type ResolvedAdventCalendarSummary = Pick<ResolvedAdventCalendar, "_id" | "_type" | "promotion">;
