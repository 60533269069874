import { Documents, objectKeys } from "@libry-content/types";
import { isTemplateCampaign } from "./campaigns";
import {
  DigitalLibraryServiceType,
  digitalLibraryTemplateConstantObject,
  digitalLibraryTemplateServiceConstantObject,
  isDigitalLibraryService,
} from "./digitalLibrary";

export type EditableType = Extract<
  Documents["_type"],
  | "site"
  | "library"
  | "event"
  | "service"
  | "list"
  | "staticPage"
  | "privacyPolicy"
  | "footerMenuCollection"
  | "sommerles"
  | "alert"
  | "recommendation"
  | "adventCalendar"
  | "employee"
  | "bibliobattles"
  | "customCampaign"
  | DigitalLibraryServiceType
>;

// Use a record in order to make sure all types are included through ts
const editableTypesRecord: Record<EditableType, true> = {
  site: true,
  library: true,
  event: true,
  service: true,
  list: true,
  staticPage: true,
  privacyPolicy: true,
  footerMenuCollection: true,
  sommerles: true,
  alert: true,
  recommendation: true,
  adventCalendar: true,
  employee: true,
  bibliobattles: true,
  ...digitalLibraryTemplateConstantObject(true),
  ...digitalLibraryTemplateServiceConstantObject(true),
  digitalLibraryCustomService: true,
  customCampaign: true,
};

const EDITABLE_TYPES = objectKeys(editableTypesRecord);

export const isEditableType = (typeName: string): typeName is EditableType =>
  (EDITABLE_TYPES as string[]).includes(typeName);

export type EditableDocument = { _id: string; _type: EditableType };

export const getEditDocumentUrl = (doc: EditableDocument) => {
  if (isDigitalLibraryService(doc)) {
    return `/cms/structure/digital-library;${doc._id}`;
  }

  if (isTemplateCampaign(doc)) {
    return `/cms/structure/campaigns;${doc._id}`;
  }

  switch (doc._type) {
    case "site":
      return `/cms/structure/site;site`;
    case "privacyPolicy":
      return `/cms/structure/site;privacyPolicy`;
    case "footerMenuCollection":
      return `/cms/structure/site;footerMenuCollection`;
    case "alert":
      return `/cms/structure/alert`;
    default:
      return `/cms/intent/edit/id=${doc._id}`;
  }
};

export type CreateNewableType = Exclude<
  EditableType,
  "site" | "privacyPolicy" | "footerMenuCollection" | "sommerles" | "bibliobattles" | "alert" | "adventCalendar"
>;

// Use a record in order to make sure all types are included through ts
const createNewableTypesRecord: Record<CreateNewableType, true> = {
  library: true,
  event: true,
  service: true,
  list: true,
  staticPage: true,
  recommendation: true,
  employee: true,
  ...digitalLibraryTemplateConstantObject(true),
  ...digitalLibraryTemplateServiceConstantObject(true),
  digitalLibraryCustomService: true,
  customCampaign: true,
};

const CREATE_NEWABLE_TYPES = objectKeys(createNewableTypesRecord);

export const isCreateNewableType = (typeName: string): typeName is CreateNewableType =>
  (CREATE_NEWABLE_TYPES as string[]).includes(typeName);

export const getCreateDocumentUrl = (type: CreateNewableType, newDocumentId: string) => {
  // Template-delen må enkodes for å fungere med forhåndsvisning
  const urlEncodedTemplate = encodeURIComponent(`,template=${type}`);
  return `/cms/structure/${type};${newDocumentId}${urlEncodedTemplate}`;
};
