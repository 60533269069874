// TODO flytte til js-utils? Brukes også i bla forrigebok. Vanskelig å bruke p-retry pga problemer esm-eksports

export type RetryConfig = {
  retries: number;
  minTimeout?: number;
  onFailedAttempt?: (status: { attemptNumber: number; retriesLeft: number; toString: () => string }) => void;
};

export const retry = async <T>(fn: () => Promise<T>, options: RetryConfig): Promise<T> => run(fn, options, 0);

const run = async <T>(fn: () => Promise<T>, options: RetryConfig, attemptNumber: number): Promise<T> => {
  try {
    return await fn();
  } catch (e) {
    if (attemptNumber >= options.retries) {
      throw e;
    }

    const message = e instanceof Error ? e.toString() : JSON.stringify(e);

    options.onFailedAttempt?.({
      attemptNumber,
      retriesLeft: options.retries - attemptNumber,
      toString: () => message,
    });

    await delay(attemptNumber, options.minTimeout);

    return await run(fn, options, attemptNumber + 1);
  }
};

const delay = async (attemptNumber: number, minDelay = 500) =>
  new Promise((resolve) => setTimeout(resolve, minDelay * (1 + attemptNumber)));
