import { Promotion } from "@libry-content/types";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import groq from "groq";

export const getPromotionFilter = (promotionPath = "") =>
  groq`(${promotionPath}promotion.startsAt <= now() && ${promotionPath}promotion.endsAt > now())`;

export const isPromotedNow = (promotion?: Promotion): boolean => {
  if (!promotion?.startsAt || !promotion?.endsAt) return false;

  const now = new Date();

  return isBefore(new Date(promotion.startsAt), now) && isAfter(new Date(promotion.endsAt), now);
};
