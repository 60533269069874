/**
 * Adds linebreaks and indents so the query is more human-readable. Since we compose our queries with javascript in several files and steps, they will most likely not be indented in a human readable way.
 * Transforms this query:
 *          *[_type == "hello"] {
 *     "myParam": "test",
 * "myObject": {...}
 *         }
 * to:
 * *[_type == "hello"] {
 *    "myParam": "test",
 *    "myObject": {
 *       ...
 *     }
 * }
 */

export const prettifyGroqQuery = (query: string) =>
  query
    // Remove comments (maybe we can find a way to keep instead? But right now they cause problems because they might break over multiple lines in ways that break the query)
    .replace(/\/\/.*\n/g, "")
    // Add linebreaks
    .replace(/,/g, ",\n")
    .replace(/{/g, "{\n")
    .replace(/}/g, "\n}")
    .replace(/\(/g, "(\n")
    .replace(/\)/g, "\n)")
    // Split each line into a string
    .split("\n")
    // Removes existing indents as they most likely are not correct when query is composed
    .map((it) => it.trim())
    // Remove empty lines
    .filter((it) => !!it)
    // Calculate correct indents for each line
    .reduce((processedLines, currentLine) => {
      const previousLine = processedLines.slice(-1)?.[0];
      // Counts the amounts of "{" and "}" on the previous line and indents appropriately
      const deltaIndents =
        (previousLine?.val.match(/({|\()/g) || []).length - (currentLine.match(/(}|\))/g) || []).length;
      const indents = (previousLine?.indents ?? 0) + deltaIndents;
      return [...processedLines, { val: currentLine, indents }];
    }, [] as { val: string; indents: number }[])
    // Create new lines with appropriate amount of indentation
    .map((it) => `${"  ".repeat(it.indents)}${it.val}`)
    // Join all lines with linebreaks between ("\n")
    .join("\n");
