import { Modify } from "@biblioteksentralen/types";
import { DecorativeImage, Publication } from "@libry-content/types";

export type PublicationWithImage = Modify<Publication, { image: DecorativeImage }>;

export const isPublicationWithImage = (publication?: Publication): publication is PublicationWithImage =>
  !!publication?.image?.asset;

export const getFirstThreePublicationsWithImage = (publications: Publication[] = []): PublicationWithImage[] =>
  (publications?.filter(isPublicationWithImage) || []).slice(0, 3);
