import { Modify } from "@biblioteksentralen/types";
import { Event, RepeatedEventCustomization } from "@libry-content/types";
import groq from "groq";

const resolveRepeatedDateSummary = groq`
  "startDate": date,
  "eventStatus": coalesce(customization.eventStatus, ^.eventStatus),
  "eventStart": coalesce(customization.eventStart, ^.eventStart),
  "eventEnd": coalesce(customization.eventEnd, ^.eventEnd),
  "_type": "repeatedDateSummary",
  "_id": _key,
  "title": ^.title
`;

export type ResolvedRepeatedDateSummary = Pick<
  RepeatedEventCustomization,
  "eventStart" | "eventEnd" | "eventStatus"
> & {
  startDate: NonNullable<Event["repeatedDates"]>[number]["date"];
  _id: string;
  _type: "repeatedDateSummary";
  title?: Event["title"];
};

const resolveRepeatedDate = groq`
  ...^, // Spread the parent event
  "promotion": null, // Erase parent promotion
  "repeatedDates": null, // Erase repeated dates array to avoid confusion
  "repeated": null, // Erase repeated flag to avoid confusion
  ...customization, // Spread the single date customization
  "startDate": date, // Single date
  "_id": _key, // Single date key as id
  "series": {
    "id": ^._id, // Series id from the parent event
    "repeatedDates": ^.repeatedDates[] {${resolveRepeatedDateSummary}}
  },
  "_type": "resolvedRepeatedEventOccurence" // Use unique type to distinguish from event-document
`;

export type ResolvedRepeatedEventOccurence = Modify<
  Omit<Event, "repeatedDates" | "repeated">,
  {
    series: { id: string; repeatedDates: ResolvedRepeatedDateSummary[] };
    _type: "resolvedRepeatedEventOccurence";
  }
>;

// By performing the flattening in the query, we can use groq's ordering and pagination.
// Note that as is this can not be used where the events are to be filtered, as in the website frontend.
export const resolveRepeatedEvents = (repeatedDateFilter = "") => groq`
  {"occurrences": select(
    repeated => repeatedDates[${repeatedDateFilter}] {${resolveRepeatedDate}},
    [@] // Non-repeated event
  )}
  .occurrences[] // Flatten
 `;

export const isRepeatedEventOccurence = <T extends ResolvedRepeatedEventOccurence>(doc: unknown): doc is T =>
  (doc as ResolvedRepeatedEventOccurence)?._type === "resolvedRepeatedEventOccurence";

export const isEvent = <T extends Event>(doc: unknown): doc is T => (doc as Event)?._type === "event";
