import { Publication } from "./libryContentSanityTypes";

type ApiError = { error: string };

export type ApiResponse<Data = {}> = Data | ApiError;

export function isApiError(response?: ApiResponse): response is ApiError {
  return (response as ApiError)?.error !== undefined;
}

export type SanityValidationStatus =
  | {
      message: string;
      type: "success" | "warning" | "error";
    }
  | { type: "idle" };

export type SanityValidationApiResponse = ApiResponse<SanityValidationStatus>;

export type AutocompleteResponse<T> = {
  results: T[];
  source?: string;
  error?: string;
};

export type BaseAutocompleteOption = {
  value: string;
};

export type PublicationAutoCompleteOption = BaseAutocompleteOption & {
  imageUrl: string | undefined;
  publication: Publication;
};

export type FindPublicationsApiResponse = ApiResponse<AutocompleteResponse<PublicationAutoCompleteOption>>;

// https://plausible.io/docs/stats-api#metrics
export type SitePlausibleData = {
  results: {
    visitors: {
      value: number;
    };
    visits: {
      value: number;
    };
    pageviews: {
      value: number;
    };
  };
};

export type SiteMetricsResponse = {
  currentYear: SitePlausibleData["results"];
  lastYear: SitePlausibleData["results"];
};

// https://plausible.io/docs/stats-api#metrics
export type PagePlausibleData = {
  results: {
    visitors: {
      value: number;
    };
    visits: {
      value: number;
    };
    pageviews: {
      value: number;
    };
  };
};

export type PageStatsResponse = ApiResponse<PagePlausibleData["results"]>;
