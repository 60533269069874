import { Modify } from "@biblioteksentralen/types";
import { LocalizedField } from "@libry-content/localization";
import { Event, Library } from "@libry-content/types";
import groq from "groq";
import { groqEventStart } from "./utils";
import { ResolvedRepeatedEventOccurence } from "./repeatedEvents";

export const resolveEventGroqProjection = groq`
  ...,
  'eventStart': ${groqEventStart},
  'eventType': eventType->label,
  'library': library->{name, _id, slug, _type}
`;

export type ResolvedEvent = Modify<
  Event | ResolvedRepeatedEventOccurence,
  {
    eventType?: LocalizedField<string>;
    library?: Pick<Library, "name" | "_id" | "slug" | "_type">;
  }
>;

export const resolveEventSummaryGroqProjection = groq`
  {${resolveEventGroqProjection}}
  {
    _id,
    _type,
    eventStart,
    startDate,
    title,
    eventStatus, 
    teaser,
    allDay,
    eventEnd,
    endDate,
    promotion,
    image,
    externalLocation,
    location,
    library,
    eventType,
    targetAudiences,
    registration,
    series { id }
  }
`;

export type ResolvedEventSummary = Pick<
  ResolvedEvent,
  | "_id"
  | "_type"
  | "eventStart"
  | "startDate"
  | "title"
  | "eventStatus"
  | "teaser"
  | "allDay"
  | "eventEnd"
  | "endDate"
  | "promotion"
  | "image"
  | "externalLocation"
  | "location"
  | "library"
  | "eventType"
  | "targetAudiences"
  | "registration"
> & { series?: { id: string } }; // series is only defined if _type === "resolvedRepeatedEventOccurence", but couldnt find a way to type this
