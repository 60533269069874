import { objectKeys, SanityDocument } from "@libry-content/types";

// The order is significant
export const targetAudiences = {
  forAll: { nb: "For alle", nn: "For alle" },
  children: { nb: "Barn", nn: "Barn" },
  olderChildren: { nb: "Eldre barn", nn: "Eldre barn" },
  youth: { nb: "Ungdom", nn: "Ungdom" },
  adults: { nb: "Voksne", nn: "Vaksne" },
  kindergardens: { nb: "Barnehager", nn: "Barnehagar" },
  schoolsAndSchoolClasses: { nb: "Skoler og skoleklasser", nn: "Skular og skuleklassar" },
} as const;

const targetAudienceCodes = objectKeys(targetAudiences);

export type TargetAudienceCode = (typeof targetAudienceCodes)[number];

export const isTargetAudienceCode = (item: unknown): item is TargetAudienceCode =>
  typeof item === "string" && (targetAudienceCodes as readonly string[]).includes(item);

export const targetAudienceCodeIndex = (code: TargetAudienceCode) => targetAudienceCodes.indexOf(code);

export type DocumentWithTargetAudiences = Partial<SanityDocument> & { targetAudiences?: TargetAudienceCode[] };
