import groq from "groq";
import { getPromotionFilter } from "../promotion/utils";

const customCampaignIsStarted = groq`(startDate <= $today || ${getPromotionFilter()})`;

export const customCampaignIsNotFinished = groq`endDate >= $today`;

export const customCampaignIsOngoingGroqFilter = groq`($showCampaignOutsideSeason || ${customCampaignIsStarted} && ${customCampaignIsNotFinished})`;

const thisYear = new Date().getFullYear();

export const campaignIsNotFinished = groq`"${thisYear}-" + endDate >= $today`;
